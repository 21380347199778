import React from "react";

const Line = ({isLoggedIn}) => {
  if (!isLoggedIn) {
    return (
      <main className="line">
        <div style={{"height": "auto", "flex-direction": "column"}} className="display">
          <img className="logo" alt="logo" src="https://app.rlot88.com/logo512.png" />
          <div style={{"margin-top": "10px"}}>เพิ่มเพื่อนเพื่อเข้าเล่นเลย</div>
        </div>
        <section style={{"margin-top": "40px", "display": "flex"}}>
          <a target="_blank" href={"https://line.me/R/app/"+process.env.REACT_APP_LIFF_STBL}><img style={{"display": "block"}} src="https://scdn.line-apps.com/n/line_add_friends/btn/th.png" alt="เพิ่มเพื่อน" height="50" border="0"></img></a>
        </section>
      </main>
    );
  }
  return null
};

export default Line;