import React from "react";

const ButtonSubType = ({ types, type, text, add }) => {
  return (
    <button className={"sub-type reverse" + (types.includes(type) ? " selected" : "")} onClick={add.bind(this, type)}>
      {text}
    </button>
  );
};

export default ButtonSubType;
