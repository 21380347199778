import React from "react";
import Bets from "./Bets";

// const Display = ({ drawedNumbers }) => {
//   const balls = drawedNumbers.map((number) => (
//     <Ball key={number} number={number} />
//   ));
//   const displayInfo = drawedNumbers.length ? balls : "Skreśl 6 liczb z kuponu";
//   return <div className="display">{displayInfo}</div>;
// };
const BetLists = ({blockedNumbers, gameSlugs, gameDigits, gameNames, gameOdds, playerBets, removeBet, updateBet}) => {
  const bets = [];

  gameSlugs.forEach((slug, key) => {
      bets.push(<Bets key={key} blockedNumbers={blockedNumbers} text={gameNames[key]} type={gameSlugs[key]} digits={gameDigits[key]} odds={gameOdds[key]} playerBets={playerBets} removeBet={removeBet} updateBet={updateBet} />)
  })

  return (
    bets
  );
};

export default BetLists;