import React from "react";

const ButtonConfirm = ({ totalStake, confirm }) => {
  totalStake = parseFloat(totalStake).toFixed(2);

  return (
    <button className="confirm" onClick={confirm}>
      ส่งโพย (฿{totalStake})
    </button>
  );
};

export default ButtonConfirm;
