import { React, useState, useEffect } from "react";

const Bet = ({ playerBets, blockedNumbers, type, digits, odds, number, updateBet, removeBet }) => {
  const [pay, setPay] = useState(0);
  const [isBlocked, setIsBlocked] = useState(false);
  const [getOdds, setGetOdds] = useState(odds);
  const [inputValue, setInputValue] = useState('');

  const handleChange = (event) => {
    let stake = event.target.value
    setInputValue(stake)
    if (getOdds < 1) {
      setPay(0)
      setInputValue('')
    } else {
      setPay((stake * getOdds).toFixed(2));
      updateBet(type, number, stake);
    }
  };

  blockedNumbers[`${type}`].numbers.forEach(function (blockedNumber) {
    if (!isBlocked && blockedNumber === number) {
      let index = blockedNumbers[`${type}`].numbers.indexOf(blockedNumber)
      setIsBlocked(true)
      setGetOdds((blockedNumbers[`${type}`].pay[index]))
    }
  });

  useEffect(() => {
    let stake = playerBets[`${type}`].stake[playerBets[`${type}`].numbers.indexOf(number)];
    
    if (stake > 0) {
      setInputValue(stake);
    } else {
      setInputValue('');
    }

    if (getOdds < 1) {
      setPay(0)
      setInputValue('')
    } else {
      setPay((stake * getOdds).toFixed(2));
    }
  })

  let ballClassName = digits === 3 ? "ball three" : "ball";
  ballClassName = isBlocked ? ballClassName + " blocked" : ballClassName;
  ballClassName = isBlocked && getOdds > 0 ? ballClassName + " odds" : ballClassName;

  let payClassName = isBlocked ? "pay blocked" : "pay"
  let disabled = isBlocked && getOdds < 1 ? true : false;

  return <div className="bet">
    <span><div className={ballClassName}>{number}</div></span>
    <span><input disabled={disabled} value={inputValue} type="number" pattern="[0-9]*" name="stake" onChange={handleChange} placeholder="฿0.00"></input></span>
    <span className={payClassName}>จ่าย {getOdds}<br/>ชนะ {pay}</span>
    <span> <button onClick={removeBet.bind(this, type, number)} className="reset">X</button></span>
  </div>;
};

export default Bet;
