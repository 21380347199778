import {React, useState} from "react";

const InputDefaultStake = ({ totalBets, updatePlayerBets }) => {
  const [inputValue, setInputValue] = useState('');

  const handleBlur = (event) => {
    setInputValue('');
  };

  const handleChange = (event) => {
    let stake = event.target.value
    setInputValue(stake);
    updatePlayerBets(stake);
  };

  if (!totalBets) {
    return null;
  }

  return (
    <section className="controls default-stake">
          <input value={inputValue} onBlur={handleBlur} onChange={handleChange} pattern="[0-9]*" placeholder="เดิมพันราคาเท่ากัน ฿0.00"></input>
    </section>
  );
};

export default InputDefaultStake;
