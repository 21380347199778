import {React, useState} from "react";
import Field from "./Field";
import CouponCategories from "./CouponCategories";

const Coupon = ({ blockedNumbers, gameSlugs, gameDigits, playerBets, types, subTypes, add, remove }) => {
  const [startNumber, setStartNumber] = useState(0);
  const numbers = [];

  const updateStartNumber = (number) => {
    setStartNumber(number);
  }

  let digits = types[0] ? gameDigits[gameSlugs.indexOf(types[0])] : 2;

  if (subTypes.length && !subTypes.includes("reverse"))  {
    digits = 1;
  }

  let className = digits === 1 ? "one" : digits === 2 ? "two" : "three";

  let useStartNumber = digits === 3 ? startNumber : 0 
  let endNumber = digits === 1 ? 10 : useStartNumber + 100;

  for (let i = useStartNumber; i < endNumber; i++) {
    let prepend = digits - i.toString().length;
    prepend = prepend ? prepend === 1 ? "0" : "00" : "";
    numbers.push(prepend+i.toString())
  }

  const fields = numbers.map((number) => (
    <Field key={number} types={types} number={number} blockedNumbers={blockedNumbers} add={add} remove={remove} playerBets={playerBets} />
  ));

  if (types.length) {
    return (
      <div className="coupon-wrapper">
        <CouponCategories digits={digits} startNumber={startNumber} updateStartNumber={updateStartNumber} />
        <div className={`coupon ${className}`}>
          {fields}
        </div>
      </div>
    );
  }

  return null;
};

export default Coupon;
