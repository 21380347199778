import React from "react";
import LineIcon from "./images/line-logo.png"

const ButtonLineShare = ({ shareLineFriend }) => {
  return (
    <section className={"controls line"}>
      <button className={"line"} onClick={shareLineFriend}>
        <img alt="line-share" width="30" src={LineIcon}></img>
        <span>แนะนำเพื่อน รับ 1%</span>
      </button>
    </section>
  );
};

export default ButtonLineShare;
