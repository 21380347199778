import React from "react";
import ButtonStartNumber from "./ButtonStartNumber";

const CouponCategories = ({ digits, startNumber, updateStartNumber }) => {
  const buttons = [];
  let selected = false;

  if (digits === 3) {
    for (let i = 0; i < 10; i++) {
      selected = i*100 === startNumber ? true : false;
      buttons.push(<ButtonStartNumber key={i} text={i*100} selected={selected} updateStartNumber={updateStartNumber} />)
    }
  }

  if (digits === 3) {
    return (
      <section className="coupon-categories">
        {buttons}
      </section>
    );
  }

  return null;
};

export default CouponCategories;
