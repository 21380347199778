import React from "react";
import Bet from "./Bet";

// const Display = ({ drawedNumbers }) => {
//   const balls = drawedNumbers.map((number) => (
//     <Ball key={number} number={number} />
//   ));
//   const displayInfo = drawedNumbers.length ? balls : "Skreśl 6 liczb z kuponu";
//   return <div className="display">{displayInfo}</div>;
// };

const Bets = ({ blockedNumbers, text, type, digits, odds, playerBets, updateBet, removeBet, removeBets}) => {
  if (playerBets[`${type}`].numbers.length) {
    const rows = playerBets[`${type}`].numbers.map((number) => (
     <Bet key={number} playerBets={playerBets} blockedNumbers={blockedNumbers} type={type} digits={digits} odds={odds} number={number} updateBet={updateBet} removeBet={removeBet} removeBets={removeBets} />
    ));
    return (
      <section className="bets">
        <h3 className="info">{text}</h3>
        {rows}
      </section>
    );
  }

  return null;
};

export default Bets;