import React from "react";

import ButtonReset from "./ButtonReset";
import ButtonConfirm from "./ButtonConfirm";

const Footer = ({ totalBets, totalStake, confirm, reset, updatePlayerBets }) => {

  if (!totalBets) {
    return null;
  }

  return (
    <section className="footer controls">
          <ButtonReset reset={reset} />
          <ButtonConfirm totalStake={totalStake} confirm={confirm} />
    </section>
  );
};

export default Footer;
