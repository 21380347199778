import React from "react";
import { Report } from 'notiflix/build/notiflix-report-aio';

const ButtonFriendStats = ({ gameData, isGameDataLoaded }) => {
  if (!isGameDataLoaded) {
    return null;
  }

  if (gameData.user.affiliates === 0) {
    return null;
  }

  const stats = () => {
    Report.info(
    'แนะนำเพื่อน',
    'เพื่อน: '+gameData.user.affiliates+'\nยอดที่ค้างอยู่: ฿'+gameData.user.affiliates_payout,
    'Okay',
    );
  };

  return (
    <section className="controls rules">
      <button  onClick={stats} className="rules">รายรับจากการแนะนำเพื่อน</button>
    </section>
  );
};

export default ButtonFriendStats;