import "./App.css";
import { React, useState, useEffect } from "react";
import { Confirm } from 'notiflix/build/notiflix-confirm-aio';
import { Report } from 'notiflix/build/notiflix-report-aio';
import { Block } from 'notiflix/build/notiflix-block-aio';
import liff from '@line/liff';

import Line from "./Line";
import ButtonLineShare from "./ButtonLineShare";
import ButtonFriendStats from "./ButtonFriendStats";
import GameButtons from "./GameButtons";
import GameTitle from "./GameTitle";
import GameInfo from "./GameInfo";
import Coupon from "./Coupon";
import BetLists from "./BetLists";
import InputDefaultStake from "./InputDefaultStake";
import Footer from "./Footer";
import stringPermutations from "./Helpers";

const App = () => {
  const [lottoTypes, setLottoTypes] = useState([]);
  const [lottoSubTypes, setLottoSubTypes] = useState([]);
  const [isGameDataLoading, setIsGameDataLoading] = useState(0);
  const [isGameDataLoaded, setIsGameDataLoaded] = useState(0);
  const [gameData, setGameData] = useState({});
  const [blockedNumbers, setBlockedNumbers] = useState({});
  const [gameSlugs, setGameSlugs] = useState([]);
  const [gameNames, setGameNames] = useState([]);
  const [gameDigits, setGameDigits] = useState([]);
  const [gameOdds, setGameOdds] = useState([]);
  const [gameSubTypes, setGameSubTypes] = useState({});
  const [betsDataStructure, setBetsDataStructure] = useState({});
  const [playerBets, setPlayerBets] = useState({});
  const [totalBets, setTotalBets] = useState(0);
  const [totalStake, setTotalStake] = useState(0);
  const [isLoadingLine, setIsLoadingLine] = useState(false);
  const [isLineLoaded, setIsLineLoaded] = useState(false);
  const [lineId, setLineId] = useState("");
  const [lineName, setLineName] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(0);
  const [isLineFriend, setIsLineFriend] = useState(0);
  const [affiliateId, setAffiliateId] = useState("");

  const addPlayerBets = (number) => {
    let bets = betsDataStructure;
    let newTotalBets = totalBets;
    let subTypeNumbers = [];
    let numbers = [number];

    gameSlugs.forEach(function (slug) {
      bets[`${slug}`].numbers = [...playerBets[`${slug}`].numbers]
      bets[`${slug}`].stake = [...playerBets[`${slug}`].stake]
    });

    subTypeNumbers = getSubTypeNumbers(number);

    if (subTypeNumbers.length) {
      numbers = subTypeNumbers;
    }

    lottoTypes.forEach(function (type) {
      numbers.forEach(function (number) {
        if (!bets[`${type}`].numbers.includes(number)) {
          bets[`${type}`].numbers.push(number);
          bets[`${type}`].stake.push(parseFloat(0));
          newTotalBets++;
        }
      });
    });

    setTotalBets(newTotalBets);
    setPlayerBets(bets);
  };

  const getSubTypeNumbers = (number) => {
    let numbers = [];

    if (lottoSubTypes.length) {
      if (lottoSubTypes[0] === 'swipe-front' || lottoSubTypes[0] === '19-goals') {
        for (let i = 0; i < 10; i++) {
          numbers.push(number.toString()+i.toString())
        }
      }
      if (lottoSubTypes[0] === 'swipe-back' || lottoSubTypes[0] === '19-goals') {
        for (let i = 0; i < 10; i++) {
          numbers.push(i.toString()+number.toString())
        }
      }
      if (lottoSubTypes[0] === 'reverse') {
        numbers = stringPermutations(number.toString())
      }
      return numbers;
    }

    return [];
  }

  const removePlayerBets = (number) => {
    let bets = betsDataStructure;
    let newTotalStake = totalStake;
    let newTotalBets = totalBets;

    gameSlugs.forEach(function (slug) {
      bets[`${slug}`].numbers = [...playerBets[`${slug}`].numbers]
      bets[`${slug}`].stake = [...playerBets[`${slug}`].stake]
    });

    lottoTypes.forEach(function (type) {
      let index = bets[`${type}`].numbers.indexOf(number);
      if (index > -1) {
        newTotalStake = totalStake - bets[`${type}`].stake[index];
        bets[`${type}`].numbers.splice(index, 1);
        bets[`${type}`].stake.splice(index, 1);
        newTotalBets--;
      }
    });
    
    setTotalBets(newTotalBets);
    setTotalStake(newTotalStake.toFixed(2));
    setPlayerBets(bets);
  };

  const removePlayerBet = (type, number) => {
    let bets = betsDataStructure;
    let newTotalStake = totalStake;
    let newTotalBets = totalBets;

    gameSlugs.forEach(function (slug) {
      bets[`${slug}`].numbers = [...playerBets[`${slug}`].numbers]
      bets[`${slug}`].stake = [...playerBets[`${slug}`].stake]
    });

    let index = bets[`${type}`].numbers.indexOf(number);
    if (index > -1) {
        newTotalStake = totalStake - bets[`${type}`].stake[index];
        bets[`${type}`].numbers.splice(index, 1);
        bets[`${type}`].stake.splice(index, 1);
        newTotalBets--;
    }

    setTotalBets(newTotalBets);
    setTotalStake(newTotalStake.toFixed(2));
    setPlayerBets(bets);
  }

  const updatePlayerBet = (type, number, stake) => {
    let bets = betsDataStructure;
    let newTotalStake = totalStake;

    stake = stake ? parseFloat(stake) : 0;

    gameSlugs.forEach(function (slug) {
      bets[`${slug}`].numbers = [...playerBets[`${slug}`].numbers]
      bets[`${slug}`].stake = [...playerBets[`${slug}`].stake]
    });

    let index = bets[`${type}`].numbers.indexOf(number);
    newTotalStake = totalStake - bets[`${type}`].stake[index] + stake;
    bets[`${type}`].stake[index] = stake;

    setTotalStake(newTotalStake.toFixed(2));
    setPlayerBets(bets);
  }

  const updatePlayerBets = (stake) => {
    let bets = betsDataStructure;
    let newTotalStake = 0;

    gameSlugs.forEach(function (slug) {
      bets[`${slug}`].numbers = [...playerBets[`${slug}`].numbers]
      bets[`${slug}`].stake = [...playerBets[`${slug}`].stake]
    });

    stake = stake ? parseFloat(stake) : 0;

    gameSlugs.forEach(function (slug) {
      bets[`${slug}`].numbers = [...playerBets[`${slug}`].numbers]
      let stakes = [];
      bets[`${slug}`].numbers.forEach(function (number) {
        let index = blockedNumbers[`${slug}`].numbers.indexOf(number)
        if (index >= 0 && blockedNumbers[`${slug}`].pay[index] === 0)
          stakes.push(0);
        else {
          stakes.push(stake)
          newTotalStake = newTotalStake + stake;
        }
      })
      bets[`${slug}`].stake = stakes;
    });

    setTotalStake(newTotalStake.toFixed(2));
    setPlayerBets(bets);
  }

  const addTypes = (type) => {
    let types =  [...lottoTypes]
    let subTypes = [...lottoSubTypes]
    if (types.includes(type)) {
      let index = lottoTypes.indexOf(type);
      if (index > -1) {
        types.splice(index, 1);
      }
    } else {
      if (types[0]) {
        let currentDigits = gameDigits[gameSlugs.indexOf(types[0])];
        let newDigits = gameDigits[gameSlugs.indexOf(type)];
        if (currentDigits !== newDigits) {
          types = [];
          subTypes = [];
        }
      }
      types.push(type);
    }
    setLottoTypes(types);
    setLottoSubTypes(subTypes);
  };
  
  const addSubTypes = (type) => {
    let types =  [...lottoSubTypes]
    if (types.includes(type)) {
      let index = types.indexOf(type);
      if (index > -1) {
        types.splice(index, 1);
      }
    } else {
      types = [type];
    }
    setLottoSubTypes(types);
  };

  const resetGame = () => {
    let bets = betsDataStructure;

    gameSlugs.forEach(function (slug) {
      bets[`${slug}`].numbers = []
      bets[`${slug}`].stake = []
    });

    setPlayerBets(bets);
    setTotalBets(0);
    setTotalStake(0);
    setLottoTypes([]);
    setLottoSubTypes([]);
  };

  const post = (contact) => {
    Block.pulse('.footer', 'กำลังส่งโพย', {
      messageFontSize: '18px',
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
      position: 'fixed',
    });

    let data = {
      "contact" : contact.toString(),
      "line" : lineId,
      "line_name" : lineName,
      "bets" : playerBets,
      "affiliate_id" : affiliateId
    }

    fetch("https://api.rlot88.com/tickets/", {
      mode: "cors",
      method: "POST",
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
      // liff.closeWindow();
      window.location.href = data.ReceiptURL;
    })
    .catch((error) => {
        console.log(error)
        Report.failure(
          'ผิดพลาด',
          'ลองใหม่อีกที',
          'OK'
        );
        Block.remove('.footer');
    })
  };

  const confirm = () => {
    if (parseInt(totalStake) < 10) {
      Report.failure(
        'ผิดพลาด',
        'ยอดเดิมพันขั้นตำ 10 บาท',
        'OK',
      );
      return;
    }

    Confirm.init({
      position: 'center',
      cssAnimationDuration: 0
    });

    Confirm.prompt(
      "Contact",
      "ใส่เบอร์ติดต่อ หรือ ไลน์ ID ของคุณ",
      "",
      "Okay",
      "Cancel",
      (value) => {
        if (value) {
          post(value)
        } else {
          console.log('cancel');
          Report.failure(
            'ผิดพลาด',
            'คุณไม่ได้ ใส่เบอร์ติดต่อ หรือ ไลน์ ID ของคุณ กรุณากด "ส่งโพย" ใหม่',
            'Okay',
          );
        }
      });

    // Report.info(
    // 'สแกน QR',
    // 'จ่ายบิลด้วย ระบบ สแกน QR พร้อม จ่าย กรุณากด "Okay"',
    // 'Okay',
    // () => {
    //   Confirm.prompt(
    //   "Contact",
    //   "ใส่เบอร์ติดต่อ หรือ ไลน์ ID ของคุณ",
    //   "",
    //   "Okay",
    //   "Cancel",
    //   (value) => {
    //     if (value) {
    //       post(value)
    //     } else {
    //       console.log('cancel');
    //       Report.failure(
    //         'ผิดพลาด',
    //         'คุณไม่ได้ ใส่เบอร์ติดต่อ หรือ ไลน์ ID ของคุณ กรุณากด "ส่งโพย" ใหม่',
    //         'Okay',
    //       );
    //     }
    //   }
    //   );
    // });
  };

  const shareLineFriend = () => {
    liff
    .shareTargetPicker(
      [
        {
          type: "text",
          text: lineName+" แนะนำคุณให้เล่นหวยที่ Rlot88 เข้าเล่นหวยเลย กดลิงก์:\n\nhttps://liff.rlot88.com/?af="+lineId,
        },
      ],
      {
        isMultiple: true,
      }
    )
    .then(function (res) {
      if (res) {
        // succeeded in sending a message through TargetPicker
        console.log(`[${res.status}] Message sent!`);
      } else {
        // sending message canceled
        console.log("TargetPicker was closed!");
      }
    })
    .catch(function (error) {
      // something went wrong before sending a message
      console.log("something wrong happen");
    });
  };

  if (!isLoadingLine) {
    setIsLoadingLine(true)
    liff.init({
        liffId: process.env.REACT_APP_LIFF_STBL // Use own liffId
        //withLoginOnExternalBrowser: true, // Enable automatic login process
    }).then(() => {
          if (liff.isLoggedIn()) {
            liff.getProfile().then((data) => {
              setLineId(data.userId)
              setLineName(data.displayName)
              liff.getFriendship().then((data) => {
                if (data.friendFlag === true) {
                  const urlParams = new URLSearchParams(window.location.search);
                  setAffiliateId(urlParams.get('af'))
                  setIsLineFriend(1)
                  setIsLoggedIn(true)
                }
                setIsLineLoaded(true)
              });
            }).catch((err) => {
              liff.logout();
              window.location.reload();
            });
          } else {
            setIsLineLoaded(true)
          }
      }).catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (isLoadingLine && !isLineLoaded) {
      Block.hourglass('.app', '', {
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        position: 'fixed',
      });
    } else {
      if(!isGameDataLoading) {
        setIsGameDataLoading(1)
        fetch("https://api.rlot88.com/games/?line="+lineId, {
          })
          .then(response => response.json())
          .then(data => {
            setGameData(data)
            setBlockedNumbers(data.blocked_numbers)
            setBetsDataStructure(JSON.parse(data.game.data_structure))
            setPlayerBets(JSON.parse(data.game.data_structure))
            setGameOdds(JSON.parse(data.game.config).odds)
            setGameDigits(JSON.parse(data.game.config).digits)
            setGameSlugs(JSON.parse(data.game.config).slugs)
            setGameNames(JSON.parse(data.game.config).names)
            setGameSubTypes(JSON.parse(data.game.config).sub_types)
            setIsGameDataLoaded(1)
            Block.remove('.app')
          })
          .catch((error) => {
        })
      }
    }
  }, [isLoadingLine, isLineLoaded, isGameDataLoading, lineId]);

  return (
    <div className="app">
      <Line isLoggedIn={isLoggedIn} />
      {isLoggedIn > 0 &&
        <main>
          <GameTitle gameData={gameData} />
          <ButtonLineShare shareLineFriend={shareLineFriend} />
          <ButtonFriendStats isGameDataLoaded={isGameDataLoaded} gameData={gameData} />
          <GameButtons gameSubTypes={gameSubTypes} gameDigits={gameDigits} gameData={gameData} isGameDataLoaded={isGameDataLoaded} gameSlugs={gameSlugs} gameNames={gameNames} gameOdds={gameOdds} lottoTypes={lottoTypes} add={addTypes} lottoSubTypes={lottoSubTypes} add_sub={addSubTypes}/>
          <GameInfo gameNames={gameNames} gameSlugs={gameSlugs} gameData={gameData} isGameDataLoaded={isGameDataLoaded}/>
          <Coupon blockedNumbers={blockedNumbers} gameSlugs={gameSlugs} gameDigits={gameDigits} playerBets={playerBets} types={lottoTypes} subTypes={lottoSubTypes} add={addPlayerBets} remove={removePlayerBets} />
          <BetLists blockedNumbers={blockedNumbers} gameSlugs={gameSlugs} gameDigits={gameDigits} gameNames={gameNames} gameOdds={gameOdds} playerBets={playerBets} removeBet={removePlayerBet} updateBet={updatePlayerBet} />
          <InputDefaultStake totalBets={totalBets} updatePlayerBets={updatePlayerBets} />
          <Footer totalBets={totalBets} totalStake={totalStake} confirm={confirm} updatePlayerBets={updatePlayerBets} reset={resetGame} />
        </main>
      }
    </div>
  );
};

export default App;
