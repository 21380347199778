import React from "react";
import ButtonType from "./ButtonType";
import ButtonSubType from "./ButtonSubType";
import ButtonReverse from "./ButtonReverse";
import { Report } from 'notiflix/build/notiflix-report-aio';

const GameButtons = ({ gameData, gameSlugs, gameNames, gameOdds, gameDigits, gameSubTypes, lottoTypes, lottoSubTypes, add, add_sub, isReverse, setReverse }) => {
  if (!gameData.open) {
    return null;
  }

  const rules = () => {
    Report.info(
    'กติกา',
    gameData.game.rules,
    'Okay',
    );
  };

  let digits = lottoTypes[0] ? gameDigits[gameSlugs.indexOf(lottoTypes[0])] : null;

  const subButtons = [];

  if (digits+"digits" in gameSubTypes) {
    gameSubTypes[`${digits}digits`].slugs.forEach((slug, key) => {
        subButtons.push(<ButtonSubType key={key} types={lottoSubTypes} type={gameSubTypes[`${digits}digits`].slugs[key]} text={gameSubTypes[`${digits}digits`].names[key]} add={add_sub} />)
    })
  }

  const buttons = [];

  gameSlugs.forEach((slug, key) => {
      buttons.push(<ButtonType key={key} types={lottoTypes} type={gameSlugs[key]} text={`${gameNames[key]} (จ่าย ${gameOdds[key]})`} add={add} />)
  })

  return (
    <>
    <section className="controls rules">
      <button onClick={rules} className="rules">กติกา</button>
    </section>
    <section className="controls">
      {buttons}
    </section>
    <section className="controls">
    </section>
    { digits > 1 &&
      <section className="controls">
        <ButtonReverse types={lottoSubTypes} type="reverse" text="กลับเลข" add={add_sub} />
      </section>
    }
    { digits < 3 &&
      <section className="controls">
        {subButtons}
      </section>
    }
    </>
  );
};

export default GameButtons;