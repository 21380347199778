import React from "react";

const ButtonStartNumber = ({ text, selected, startNumber, updateStartNumber }) => {
  const className = selected ? "category selected" : "category";
  const number = text;

  if (text === 0) {
    text = "000";
  }
  
  return (
    <button className={className} onClick={updateStartNumber.bind(this, number)}>
      {text}
    </button>
  );
};

export default ButtonStartNumber;
