import React from "react";

const ButtonType = ({ types, type, text, add }) => {
  return (
    <button className={"type" + (types.includes(type) ? " selected" : "")} onClick={add.bind(this, type)}>
      {text}
    </button>
  );
};

export default ButtonType;
