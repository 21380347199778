import React from "react";

const Field = ({ types, playerBets, number, blockedNumbers, add, remove }) => {
  let selected = true;
  let isBlocked = false;
  
  types.forEach(function (type) {
    selected = playerBets[`${type}`].numbers.indexOf(number) > -1 && selected ? true : false;
    blockedNumbers[`${type}`].numbers.forEach(function (blockedNumber) {
      if (!isBlocked) {
        isBlocked = blockedNumber === number ? true : false;
      }
    });
  });

  let action = selected ? remove : add;
  let className = selected ? "number selected" : "number";
  className = isBlocked ? className + " blocked" : className;
  
  return (
    <div className={className} onClick={action.bind(this, number)}>
      {number}
    </div>
  );
};

export default Field;
