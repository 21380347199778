import React from "react";

const GameTitle = ({ gameData }) => {
  if (!gameData.game) {
    return null;
  }
  return (
    <div className="display">
      <span>{gameData.game.name}</span>
      <span style={{"fontSize": "20px"}}>{gameData.round.name}</span>
    </div>
  );
};

export default GameTitle ;
