import React from "react";
import { table } from 'table';

const GameInfo = ({ gameData, gameSlugs, gameNames, isGameDataLoaded }) => {
  if (!isGameDataLoaded) {
    return null;
  }

  if (gameData.open) {
    return null;
  }

  if (gameData.has_closed && gameData.waiting_results) {
    return (
      <div className="display">
        ปิดรับแทง...กำลังรอผล
      </div>
    );
  }

  if (gameData.has_closed && !gameData.waiting_results) {
    let tableData = [];
    for (const [key, value] of Object.entries(JSON.parse(gameData.round.results))) {
      let data = [];
      let nameKey = gameSlugs.indexOf(key)
      data.push(gameNames[nameKey])
      data.push((( value instanceof Array ) ? value.join(', ') : value))
      tableData.push(data);
    }
    console.log(tableData);
    let renderTable = table(
        tableData,
        {
          columnDefault: {
            width: 20,
          },
          columns: [
            { alignment: 'left' },
            { alignment: 'right' },
          ],
          border: {
            topBody: `-`,
            topJoin: ``,
            topLeft: ``,
            topRight: ``,

            bottomBody: `-`,
            bottomJoin: ``,
            bottomLeft: ``,
            bottomRight: ``,

            bodyLeft: ``,
            bodyRight: ``,
            bodyJoin: ``,

            joinBody: `-`,
            joinLeft: ``,
            joinRight: ``,
            joinJoin: ``
          }
        }
    );
    return (
      <>
      <div className="display">
        ปิดรับแทง...ผลออกแล้ว
      </div>
      <section className="results bets">
        <pre>
          {renderTable}
        </pre>
      </section>
      </>
    );
  }

  if (!gameData.open && !gameData.has_closed) {
    return (
      <div className="display">
        เปิดรับแทง:<br />{gameData.round.opens_at}
      </div>
    );
  }
};

export default GameInfo;
